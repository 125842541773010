import React from 'react'
import { Link } from 'gatsby'
// import FacebookIcon from '../../static/svg/facebook-icon.svg'

const Footer = ({className = ''}) => {
  return (
    <div className={`mt-6 bg-black py-2 text-sm w-full ${className}`} id='footer'>
      <div className="container w-full md:flex md:justify-between">
        <ul className="max-w-4xl flex justify-center items-center md:justify-start pb-2 md:pb-0 flex-wrap space-y-1">
          <li className="px-3 md:px-4 font-semibold">
            <Link to="/" className="text-white">
              HOME
            </Link>
          </li>
          <li className="px-3 md:px-4 font-semibold">
            <Link to="/about" className="text-white">
              ABOUT
            </Link>
          </li>
          <li className="px-3 md:px-4 font-semibold">
            <Link to="/for-sale" className="text-white uppercase">
              For sale
            </Link>
          </li>
          <li className="px-3 md:px-4 font-semibold">
            <Link to="/golf-car-rentals" className="text-white uppercase">
              Rentals
            </Link>
          </li>
          <li className="px-3 md:px-4 font-semibold">
            <Link to="/services" className="text-white">
              SERVICES
            </Link>
          </li>
          <li className="px-3 md:px-4 font-semibold">
            <Link to="/car-ports" className="text-white">
              CARPORTS
            </Link>
          </li>
          <li className="px-3 md:px-4 font-semibold">
            <Link to="/contact" className="text-white">
              CONTACT
            </Link>
          </li>
          <li className="px-3 md:px-4 font-semibold">
            <a href="https://www.facebook.com/WestviewGolfCars/" className="inline-block" target='_blank' rel="noreferrer">
              <img src='../../images/facebook-icon.jpg' alt='facebook-icon' className='inline-block w-10 h-10' />
            </a>
          </li>
          <li className="">
            <a href="https://www.bbb.org/us/mi/paw-paw/profile/golf-carts/westview-golf-cars-more-llc-0372-38294769" className="inline-block" target='_blank' rel="noreferrer">
              <img src='../../images/bbb-logo.svg' alt='bbb-logo' className='inline-block w-24 h-10' />
            </a>
          </li>
        </ul>
        <p className="text-white font-semibold text-center md:text-right pr-4">
          COPYRIGHT © WESTVIEW GOLF CARS {new Date().getFullYear()} | ABOVE MEDIA
        </p>
      </div>
    </div>
  )
}

export default Footer
